.sidebar-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  color: #33ca8f;

  /* For Tablet View */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 16px;
  }
}

.page-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  color: #151852;

  // display: flex;
  // flex: 1;
  /* For Tablet View */
  @media screen and (min-device-width: 640px) and (max-device-width: 1024px) {
    font-size: 20px;
    margin-top: 18px;
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    font-size: 28px;
    margin-top: 18px;
  }
}

.sidebar-link {
  font-size: 18px;

  /* For Tablet View */
  @media screen and (min-device-width: 640px) and (max-device-width: 1024px) {
    font-size: 16px;
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    font-size: 14px;
  }
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;

  @media (max-width: 767px) {
    >* {
      padding: 0 0 20px 0;
    }
  }
}

.categories-btn {
  border: 1px solid #33ca8f;
  border-radius: 4px;
  padding: 19px 14px;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
  background: transparent;
}

.filters {
  font-size: 14px;
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 991px) {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;

    >* {
      flex-grow: 1;
    }

    .flex {
      flex-direction: column;
      align-items: flex-start;

      span {
        margin-bottom: 5px;
      }

      .dropdown {
        width: 100%;
      }
    }
  }
}

.dropdown-toggle::after {
  display: none;
}

.custom-dropdown {
  transition: 0.3s;
  font-size: 14px;
  padding: 0;
  border-radius: 6px;
  border: 1px solid #4cf7b7;
  text-align: center;

  .dropdown-toggle,
  button {
    display: inline-block;
    padding: 13px 22px;
    width: 100%;
  }

  &.gradient-bg {
    border: none;
  }

  &:hover {
    >a {
      color: #212529;
    }

    &.gradient-bg>a {
      color: #fff;
    }
  }

  i {
    margin-left: 10px;
  }

  &.active {
    background: linear-gradient(to bottom, #33ca8f, #0bb8c8);

    button {
      color: #fff;
    }
  }

  button {
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background: 0;

    &:focus {
      outline: none;
    }
  }

  @media (max-width: 767px) {
    margin-right: 0 !important;

    // width: 100%;
    // max-width: 130px;
    .dropdown-toggle,
    button {
      padding: 10px 13px;
      font-size: 12px;
    }
  }
}

.dropdown-menu,
.mainCard {
  a:hover {
    color: #33ca8f;
  }
}

.custom-menu {
  margin: 5px 0 0;
  padding: 5px 0;
  min-width: 240px;
  max-height: 300px;
  overflow-y: auto;

  &.custom-responsive-menu {
    @media screen and (max-width: 390px) {
      min-width: 225px;
    }

    @media screen and (max-width: 360px) {
      min-width: 210px;
    }
  }

  &--filtered {
    padding-top: 0;
  }

  &__filter {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 10px;

    input {
      display: block;
      width: 100%;
      height: 44px;
      border: 1px solid #eee;
      border-radius: 4px;
      font-size: 14px;
      padding: 0 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  }
}

.custom-item {
  padding: 6px 12px;
  transition: 0.3s;
  font-size: 13px;
  display: block;
  width: 100%;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.custom-item.active {
  color: #fff;
  background-color: #33ca8f;
  transition: 0.3s;
}

.custom-item.active:hover {
  color: #fff !important;
  transition: 0.3s;
}

.custom-item:hover {
  color: #33ca8f;
  transition: 0.3s;
}

.sidebar-btn {
  flex: 1;
}

.navbar-toggler {
  line-height: inherit;
  border-radius: 0;
}

.nav-link.active {
  color: #33ca8f;
  font-weight: 500;
}

.bookmark-btn {
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
}

.bookmark-btn:focus {
  outline: 0;
}

.image-container {
  width: 180px;
  height: 180px;

  @media screen and (min-width: 1024px) {
    width: 150px;
    height: 150px;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 120px;
    height: 120px;
  }

  /* For Mobile Portrait View */
  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    width: 180px;
    height: 180px;
  }

  /* For Mobile Landscape View */
  @media screen and (max-device-width: 640px) and (orientation: landscape) {
    width: 100px;
    height: 100px;
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    width: 100px;
    height: 100px;
  }
}

.masonry-grid {
  margin: 0px 0px !important;

  @media screen and (max-device-width: 640px) {
    margin: 0px 30px !important;
  }
}

.masonry-grid_column {
  padding-left: 0 !important;
}

.mainCard {
  @media screen and (max-device-width: 640px) {
    //margin: 0px 12px !important;
  }
}

.sidebar-bg {
  background: #dffeff;
}

.main-bg {
  background: linear-gradient(180deg, #ebfafd 0%, #ffffff 100%);
}

.card-text {
  font-size: 14;
  color: "#50597C";

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 12px;
  }
}

// FONT SIZES...
.main-title {
  margin: 0;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 24px;
  color: #151852;
}

.card-title {
  font-weight: 600 !important;
  font-size: 1.125em;
  line-height: 24px;
  color: #151852;
  font-family: "Poppins", sans-serif;
  margin: 0 0 8px 0;
  padding: 0;
}

.resources-card .formal-content.collapsed .card-description{
  font-size: 12px;
  line-height: 20px;
  font-weight: 400 !important;
  color: #6f708a;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto 18px;
  // -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins", sans-serif;
  padding-right: 60px;
  height: auto;
  max-height: 60px;
  transition: all 0.2s ease-in-out;
  // position: relative;
}


.resources-card .formal-content .card-description {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400 !important;
    color: #6f708a;
    display: -webkit-box;
    -webkit-line-clamp: initial;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: inherit;
   
    max-width: 100%;
    margin: 0 auto 18px;
    font-family: "Poppins", sans-serif;
    padding-right: 60px;
    height: auto;
    max-height: 1000px;
    // transition: all .5s ease-in-out;
}

.resources-card .uniqueFieldData{
  cursor: initial;
}


.OFF {
  display: block;
  margin-bottom: auto;
}

// .resources-card .resources-content{
//   max-height: 100px;
//   transition: max-height 4s ease-out;
// }
// .resources-card .resources-content:hover{
//   max-height: 500px;
//   transition: max-height 6s ease-in;
// }

// .resources-card .collapsing{
//   transition: height 0.3s ease;
// }

.resources-card .resources-content{
  z-index: 2;
}
.icon-section {
  margin: 0 0 18px 0;
  padding: 0 40px 0 0;
}

.icon-section ul {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 8px;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 6px;
    grid-template-columns: 18px 1fr;
    align-items: center;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 10px;
    text-align: justify;
    color: #151852;
    font-family: "Poppins", sans-serif;

    a {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 10px;
      text-align: justify;
      color: #151852;
      font-family: "Poppins", sans-serif;
    }
    .address{
      font-weight: 500;
      font-size: 0.875em;
      line-height: 15px;
      text-align: left;
      color: #151852;
      font-family: "Poppins", sans-serif;
    }
  }
}

.link-button-section {
  display: flex;
  grid-gap: 8px;
  padding-right: 40px;
  z-index: 2;
  a {
    background: #ffffff;
    border: 1px solid #33ca8f;
    box-sizing: border-box;
    border-radius: 2px;
    font-weight: 500;
    font-size: 0.625em;
    line-height: 10px;
    color: #33ca8f;
    padding: 4px 9px;
  }

  a:hover {
    border: 1px solid #151852;
    color: #151852;
  }
}

.card-links {
  font-size: 12px !important;

.resources-card{
  position: relative;
  overflow: hidden;
}
.resources-card .botton-right-image{
  position: absolute;
}
.resources-card .botton-right-image .right-ellipse-send-action-plan{
    position: absolute;
    width: 54%;
    bottom: -5px;
}
.resources-card .botton-right-image .gray-leaf-image{
    position: absolute;
    width: 41%;
    bottom: 0px;
    transform: rotate(10deg);
    right: 46px;
}


  @media screen and (min-width: 1024px) {
    font-size: 11px !important;
  }
 
  /* For Tablet View */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 10px !important;
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    font-size: 9px !important;
  }
}

.card-tags {
  font-size: 14px !important;
}

.sidebar-title {
  font-size: 24px !important;
  font-weight: 500 !important;

  @media screen and (min-width: 1024px) {
    font-size: 22px !important;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 20px !important;
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    font-size: 18px !important;
  }
}

.sidebar-link {
  font-size: 18px !important;

  @media screen and (min-width: 1024px) {
    font-size: 16px !important;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 14px !important;
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    font-size: 14px !important;
  }
}

.filter-links {
  font-size: 14px !important;

  @media screen and (min-width: 1024px) {
    font-size: 12px !important;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 10px !important;
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    font-size: 8px !important;
  }
}

.card-container {

  /* For Tablet View */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding: 0 1rem !important;
  }

  @media screen and (min-device-width: 640px) and (max-device-width: 768px) {
    padding: 0.5rem !important;
  }

  @media screen and (max-device-width: 640px) {
    padding: 0.5rem !important;
  }
}

.MuiChip-label {
  padding-right: 18px !important;

  @media screen and (min-width: 1024px) {
    font-size: 17px !important;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 16px !important;
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    font-size: 15px !important;
  }
}

@media (max-width: 992px) {
  .icon-section {
    padding: 0;
  }
}

.resources-card {
  position: relative;
}

.resources-card.disable-card::after {
  position: absolute;
  content: '';
  background-color: lightgray;
  width: 100%;
  height: 100%;
  border-radius: 17px;
  border: 1px solid lightgray;
  opacity: 0.4;
}

.resources-card.VideoResources-icon.disable-card::after {
  border-radius: 12px;
}

.resources-card.disable-card {
  border: 1px solid lightgray;
  cursor: not-allowed;
  z-index: 2;
}

@media (max-width: 767px) {
  .title-container {
    display: block;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
  }

  .resources-card {
    background: #ffffff;
    border: 1px solid #4cf7b7;
    border-radius: 18px;
    // padding: 12px !important;
  }

  .icon-section {
    padding: 0;
  }
}

@media (max-width: 320px) {
  .icon-section ul {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 8px;
  }

  footer {
    padding: 30px 10px !important;
  }
}

.chips-container {
  list-style-type: none;
  display: -webkit-flex;
  display: flex;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.chip {
  border: 1px solid #33ca8f;
  border-radius: 100px;
  padding: 10px 10px 10px 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #33ca8f;
  margin: 0 4px 8px 0;
  cursor: pointer;
}
.bookmark-btn.disable-card{
  opacity: 0.4;
    cursor: not-allowed;
}