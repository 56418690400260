.common-block{
    background-color: transparent;
}
.common-pap-link{
    margin-left: 0.6em;
    font-size: 18px;
    /* line-height: 29px; */
    color: #1B84CD;
    text-decoration: underline;
}
.without-color{
    background-color: transparent !important;
    padding-top: 0 !important;
}
.green-block{
background-color: #E1F4F4;
padding: 1rem;
border-radius: 12px;
}
.green-block-heading{
    font-size: 24px !important;
    color: #F3763E !important;
    font-weight: 400 !important;
}
.many-block{
    padding: 0;
    background-color: #E1F4F4 !important;
    border-radius: 12px !important;
    padding: 1rem !important;
}
.many-block strong{
    margin-bottom: 1rem;
    display: block;
}
.pap-content-block{
    margin-bottom: 0.3em !important;
}