.close-btn {
    background-color: #33CA8F;
    border: none;
    color:white;
    outline: none;

}





@media screen and (max-width:570px){
    .responsive-layout{
        grid-template-columns: auto !important;
        display: flex !important;
    }
}
@media screen and (max-width:390px){
    .responsive-layout h3{
        max-width: 38%;
        line-height: 1.4rem;
    }
}