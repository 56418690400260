.assessment-timer {
  margin-top: 201231px;
  display: flex;
  justify-content: center;

  &__text {
    color: #33ca8f;
  }
}

.timerBlock-alignment{
  padding-left: 90px;
  padding-top: 2px;
  display: inline-block;
}
@media screen and (max-width:570px) {
  .timerBlock-alignment{
    padding-left: 10px;
    width: 50px;
  }
}