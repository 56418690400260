.masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.masonry-grid_column {
  padding-left: 15px;
  background-clip: padding-box;
}

.masonry-grid_column > div {
  margin: 15px auto;
}
