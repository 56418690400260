.top-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 18px 30px 0 30px;

  > div {
    margin-top: 0 !important;
    margin-bottom: 6px !important;
    margin-right: 6px !important;
  }
}

.summary-h3 {
  font-size: 1.5rem;
  margin: 1.5rem 0;
  padding: 0 30px;
}

.progress-chart {
  padding: 0 30px;
}

.stretch-description {
  flex: 1 0 50% !important;
  max-width: 100%  !important;
}

.modal {
  height:100%;
  margin:auto;
}

.therapy-education-links {
 text-decoration: underline;
 max-width: 100%;
}
.summary-add-icon{
  border: 1px solid #5f6477;
    border-radius: 100px;
    // padding: 3px;
    width: 24px;
    height: 24px;
    text-align: center;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    img{
      margin: 0 !important;
    }
}
.verified-icon{
  border: 1px solid #33ca8f;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  text-align: center;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  img{
    width: 12px
  }
}
.verified-icon img{
    max-width: none !important;
    margin-top: 0 !important;
}
.summary-list-container {
  display: flex;
  align-items: center;
  // display: inline-flex;
  // justify-content: space-between;
}
.top-btn.hover {
  box-shadow: 0 2px 20px rgba(0,0,0,.1);
  transition: .3s;
  .arrow {
    opacity: 0;
    transition: .5s;
  }
  p:nth-of-type(1) {
    opacity: 0;
    visibility: hidden;
    transition: .5s;
  }
  p:nth-of-type(2) {
    opacity: 1;
    visibility: visible;
    transition: .5s;
  }
  p.number{
    opacity: 1 !important;
    visibility: visible !important;
    transition: .5s !important;
  }
}

.loader-part{
  margin-left: 5px;
  margin-top: 4px;
}

#sync-calendar-btn{
  color: #69696f !important;
  background: transparent !important;
  font-size: inherit !important;
  cursor: pointer;
}

#sync-calendar-btn a:hover{
  color: #69696f !important;
}

.synced-tooltip{
  font-size: 14px;
  padding: 6px 16px 8px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #fff;
  width: 220px;
  position: absolute;
  top: 37px;
  left: -67px;
  z-index: 2;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  display: none;
  &.tooltip-top {
    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 0px;
        left: 50%;
        top: 5px;
        border: 8px solid transparent;
        border-top: 0;
        border-bottom: 8px solid #fff;
        transform: translate(-50%, calc(-100% - 5px));
    }
}
}

.synced-tooltip-part{
  position: relative;
}

.synced-tooltip-part:hover{
  .synced-tooltip{
      display: block;
  }
}

.sync-event-container{
  padding-left: 1%;
}

.sync-event-container span {
  text-decoration: underline;
}

.assessment-event-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .summary-center-title-span{
    white-space: nowrap;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width:1024px){
  .assessment-event-container{
    flex-wrap: wrap;
  }
}
.sync-event-container {
  .navbar-nav{
    width: auto;
    float: none;
    padding: 0;
    padding-top: 0;
    background-color: transparent;
    box-shadow: none !important;
    margin-top: 0;
    &::before{
      display: none;
    }
    li{
      font-family: 'Poppins' !important;
    }
    .nav-link {
      cursor: pointer;
      color: #151852 !important;
      font-weight: 400 !important;
     
    }
  }
}

@media screen and (max-width:991px) {
  .summary-center-title-span{
    text-align: center;
  }
}

.sync-event-container .more-dropdown-part .dropdown-menu{
  transform: translate3d(-50px, 36px, 0px) !important;
}
.more-dropdown-part.summary-part-more .link-part .sync-btn{
  width: 52%;
}
.more-dropdown-part.summary-part-more .link-part .schedule-link{
  padding-left: 0.8rem;
}