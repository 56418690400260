.floating_action {
  position: fixed;
  bottom: 7%;
  right: 5%;
  display: flex;
  z-index: 10;
  background: rgba(233, 236, 239, 0.98);
  border-radius: 35px;
  padding: 5px;
  overflow: hidden;
  width: 68px;
  height: 60px;
  transition: 1s;

  &__hidden_group {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(236, 95, 67);
    border: none;
    outline: none;

    &:focus {
      outline: none;
    }
  }
}

.show_all {
  width: 175px;
  transition: 1s;
  background: rgba(233, 236, 239, 0.55);
}

.notification {
  width: 30px;
}

.plus_button {
  width: 37px;
}

.checklist_logo {
  width: 37px;
  position: relative;
  left: 5px;
}

.floating_button_close {
  border: none;
  border-radius: 40px;
  padding: 5px;
  margin-left: 10px;
  background-color: rgb(212, 235, 241);
  max-width: 35px;
  max-height: 35px;
  align-self: center;

  &:focus {
    outline: none;
  }
}
