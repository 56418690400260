.assessment-details-card{
    border: 2px solid #dfefef;
    border-bottom: 2px solid #dfefef !important;
    border-radius: 0.3em !important;
    margin-bottom: 18px !important;
    .card-header{
        background-color: #f2fafa;
        border-radius: 0 !important;
        .accordion-title{
            font-size: 1.2em;
            font-family: "CircularStdBook",Arial,Helvetica,sans-serif;
            margin: 0;
            color: #383940;
            display: block;
            font-weight: 500;
        }
        img{
            width: 1.5em !important;
        }
    }
    .topIcon{
        background-image: url('../assets/images/circle-angle-top.svg');
        width: 1.5em !important;
        height: 1.5em !important;
        transform: rotate(180deg);
        content: "" !important;
        &.fa-chevron-up{
            background-image: url('../assets/images/circle-angle-top.svg');
            transform: rotate(0deg);
            content: "" !important;
            &::before{
                content: "" !important; 
            }
        }
    }
    .card-body {
        background-color: #fff;
        div{
            background-color: #f2fafa;
            padding: 1rem;
            margin-bottom: 1rem;
            &:nth-child(even){
                background-color: #f8fafa;
            }
        }
        p{
            color: #383940;
            font-weight: 500;
            font-size: 20px;
            margin: 0;
            font-weight: 400;
        }
        span{
            font-size: 18px;
            line-height: 24px;
            color: #505050;
            margin: 0;
            display: block;
            font-weight: 400;
            margin-top: 0.8rem;
        }
    }
}