.link {
    color: #e04c1c;
    text-decoration: none;
    cursor: pointer;
}

.paragraph {
    margin-bottom: 30px !important;
    line-height: 23px;
}

.header {
    color: #33ca8d;
}

.modalBody {
    padding: 0 1rem !important;
}

.logo {
    background: none;
}

.input {
    border-left: none;
}

.input:focus {
    outline: none;
    box-shadow: none;
    border-color: none;
}

.input-right {
    background: #33CA8F;
    text-align: center;
    font-weight: 700;
    color: white;
    padding: 0 1.5rem;
    cursor: pointer;
}

.input-logo {
    max-width: 45px;
}

.logo-image {
    height: 1rem;
    width: auto;
}

.modal_header {
    padding: 0rem 1rem 1rem 0;
}

.footer-link {
    color: #33CA8F;
}

.footer-link:hover {
    text-decoration: none;
    color: #33CA8F;
}

.modal_footer {
    padding: 0;
}

.modal_button {
    flex-wrap: wrap;
}

.submit-button {
    width: 100%;
    border: none;
    background-color: #33CA8F;
}

.submit-button:hover {
    text-decoration: none;
    background-color: #33CA8F;
}

.submit-button:focus {
    outline: none;
    background-color: #33CA8F;
}

.submit-button:active {
    outline: none;
    background-color: #33CA8F;
}

.p-top {
    margin: 20px 0 10px 0;
}

.p-bottom {
    margin: 0 0 20px 0;
}

.privacy-policy-text {
    background: rgb(223 255 243);
}

.privacy-policy-text li strong {
    color: #33ca8e;
}

.privacy-policy-text .link {
    color: #33ca8e;
    word-break: break-word;
}

.panel-inner h2 {
    color: #33ca8e;
}

.custom-control-input:focus,
.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 3px;
    top: 3PX;
}

.custom-checkbox .custom-control-label::after {
    top: 3px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #33CA8F;
    background-color: #33CA8F;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: rgb(209, 209, 209);
}