.icon-cards {
  position: relative;
  width: 60vw;
  height: 200px;
  max-width: 930px;
  margin: 0 auto;
  display: block;
  // color: white;
  perspective: 1000px;
  transform-origin: center;

  // This is the element that rotates with the animation

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: translateZ(-30vw) rotateY(0);
    animation: carousel 10s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards;

    &.step-animation {
      animation: carousel 8s infinite steps(1) forwards;
    }
  }

  // Individual cards

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 36px 40px rgba(95, 100, 119, 0.12);
    overflow: hidden;
    border-radius: 12px;
    transform-origin: center;

    &:nth-child(1) {
      background: #ffffff;
      transform: rotateY(0) translateZ(35vw);
    }

    &:nth-child(2) {
      background: #ffffff;
      transform: rotateY(120deg) translateZ(35vw);
    }

    &:nth-child(3) {
      background: #ffffff;
      transform: rotateY(240deg) translateZ(35vw);
    }
  }
}

// Carousel animation

@keyframes carousel {
  0%,
  17.5% {
    transform: translateZ(-35vw) rotateY(0);
  }
  27.5%,
  45% {
    transform: translateZ(-35vw) rotateY(-120deg);
  }
  55%,
  72.5% {
    transform: translateZ(-35vw) rotateY(-240deg);
  }
  82.5%,
  100% {
    transform: translateZ(-35vw) rotateY(-360deg);
  }
}
.newsHeading {
  font-size: 2.5vw;
  padding-bottom: 2.5vw;
}
figure p {
  font-size: 1.5vw;
  padding: 1vw;
}
figure p span {
  font-size: 1vw;
  color: #999ba4;
}
.tab-content {
  position: relative;
}
.tabShadow {
  position: absolute;
  left: 15px;
  right: 42px;
  top: -4px;
  z-index: 999;
  height: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  visibility: hidden;
  opacity: 0;
  background: rgba(236, 236, 236, 1);
  background: -moz-linear-gradient(
    top,
    rgba(236, 236, 236, 1) 0%,
    rgba(255, 255, 255, 0.33) 67%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(236, 236, 236, 1)),
    color-stop(67%, rgba(255, 255, 255, 0.33)),
    color-stop(100%, rgba(255, 255, 255, 0))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(236, 236, 236, 1) 0%,
    rgba(255, 255, 255, 0.33) 67%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(236, 236, 236, 1) 0%,
    rgba(255, 255, 255, 0.33) 67%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(236, 236, 236, 1) 0%,
    rgba(255, 255, 255, 0.33) 67%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(236, 236, 236, 1) 0%,
    rgba(255, 255, 255, 0.33) 67%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ececec', endColorstr='#ffffff', GradientType=0 );
  &.activeShadow {
    height: 100px;
    visibility: visible;
    opacity: 1;
  }
}
