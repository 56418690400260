.end-session-notification {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  top: 40%;
  width: 690px;
  padding: 30px;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 52px 0px rgba(34, 60, 80, 0.11);
  -moz-box-shadow: 4px 4px 52px 0px rgba(34, 60, 80, 0.11);
  box-shadow: 4px 4px 52px 0px rgba(34, 60, 80, 0.11);

  &__header {
    margin-top: 10px;
  }

  &__submit {
    border: none;
    outline: none;
    width: 30%;
    display: flex;
    justify-content: center;
    justify-self: center;
    margin: auto;
    align-items: center;
    background-color: #e6e6e6;
    border-radius: 25px;
    box-shadow: none !important;
    padding: 10px;

    &:hover {
      background: #cfe7eb;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

.close_button {
  align-self: flex-end;
  border: none;
  border-radius: 40px;
  background-color: #dadada;
  width: 30px;
  height: 30px;

  &:focus {
    outline: none;
  }
}
