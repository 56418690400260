@font-face {
  font-family: "TiemposHeadline";
  src: local("TiemposHeadline"),
    url("../assets/fonts/TiemposText-Regular.woff") format("woff");
}

@font-face {
  font-family: "CircularStdBook";
  font-style: normal;
  src: local(""), url("../assets/fonts/CircularStdBook.woff") format("woff");
}

// hover block design
.resources-card {
  // transition: 1s;
  // &.highlight{
  //   background-color: #dffff3;
  //   border: 1px solid #0eb9c2;
  // }
}

.button-bottom-grid {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  z-index: 2;
}

.resources-card:hover {
  background-image: none;
  box-shadow: 0px 36px 90px rgba(95, 100, 119, 0.12);
}

.resources-card.resource-card-video:hover {
  background-image: block !important;
}

.resources-card:hover .hover-content-block {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 18px;
}

.resources-card .hover-content-block {
  // transition: 1s;
}

.hover-content-block p.hover-visible-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6f708a;
  font-size: 1em;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.hover-content-block .bottom-part {
  width: 100%;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: initial;
  margin-top: 18px;
}

.hover-content-block .bottom-part .icon-section {
  padding: 0;
}

.hover-content-block .bottom-part .icon-section ul {
  grid-template-columns: auto;
  align-items: center;
  grid-gap: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hover-content-block .bottom-part .link-button-section {
  padding-right: 0;
}

.hover-content-block .hover-bookmark {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 18px;
  margin-bottom: auto;
}

.hover-content-block .content-link {
  margin-bottom: auto;
}

.hover-content-block {
  display: none;
}

.resources-card:hover .formal-content {
  display: flex;
}

.formal-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.formal-content .image-part {
  position: relative;
  display: block;
}

.formal-content .image-part .bookmark-with-image {
  position: absolute;
  position: absolute;
  top: 18px;
  right: 18px;
  display: none;
}

.formal-content .image-part.available-image .bookmark-with-image {
  display: block;
}

.formal-content .image-part.available-image .bookmark-with-image .bookmark-radius {
  border-radius: 4px;
}

.formal-content .content-part .headerCard.with-image-header button.bookmark-btn {
  display: none;
}

.formal-content .content-part {
  padding: 18px;
}

.resources-card {
  background: #FFFFFF;
  border: 1px solid #4cf7b7;
  border-radius: 18px;
  cursor: pointer;
  margin-bottom: auto;
  // padding: 18px;
}

.bottom-part {
  margin-top: auto;
}

.video-thumbnail-div {
  width: 100%;
  height: 300px;
  overflow: hidden;

  // border-radius: 12px;
  // margin-bottom: 18px;
  @media (max-width: 1440px) {
    height: 250px;
  }
}

.video-thumbnail-div img {
  object-fit: fill;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-position: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.resources-card.Resources-icon {
  background-image: url("../assets/images/icon_idea.png");
  background-repeat: no-repeat;
  background-position: 96% 92%;
}

.resources-card.videoResources-icon {
  background-image: url("../assets/images/icon_video.png");
  background-repeat: no-repeat;
  background-position: 96% 92%;
}

.resources-card.websites-icon {
  background-image: url("../assets/images/icon_computer.png");
  background-repeat: no-repeat;
  background-position: 96% 92%;
}

.resources-card.Papers-icon {
  background-image: url("../assets/images/icon_note.png");
  background-repeat: no-repeat;
  background-position: 96% 92%;
}

.resources-card.academicPapers-icon {
  background-image: url("../assets/images/icon_note.png");
  background-repeat: no-repeat;
  background-position: 96% 92%;
}

.resources-card.rssFeed-icon {
  background-image: url("../assets/images/icon_wifi.png");
  background-repeat: no-repeat;
  background-position: 96% 92%;
}


.topCard {
  padding: 0 !important;
}

.headerCard {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
}

.cardTitle {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  color: #151852;

  @media (max-width: 640px) {
    font-size: 16px;
  }
}

.card-text {
  @media (max-width: 640px) {
    font-size: 12px !important;
  }
}

.cardDescription {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 150 !important;
  font-size: 14px;
  line-height: 20px;
  color: #50597c;
}

.cardContent {
  display: flex;
  flex-direction: row;
}

.cardContactBlock {
  display: "flex";
}

.cardContactContent {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 200 !important;
  font-size: 12px;
  line-height: 8px;
  color: #151852;
  display: "flex !important";
  align-items: "center !important";
}

.cardTag {
  margin: 2px;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 150 !important;
  font-size: 12px;
  // line-height: 8px;
  text-align: center;
  padding: 6px 12px;

  color: #000000;
}

.fullWidthData li {
  display: flex !important;
  align-items: flex-start !important;
  margin-bottom: 10px !important;
}

.fullWidthData .icon-part img {
  margin-right: 6px;
}

.fullWidthData .contentPart{
  text-align: left;
}

.fullWidthData .icon-part {
  display: flex;
  align-items: flex-start;
  min-width: 140px;
  font-weight: 600;
  width: 140px;
  max-width: 140px;
  line-height: 20px;  
  text-align: left;
  word-break: break-word;
}
.fullWidthData .seprate{
  margin: 0;
  border-bottom: 1px solid #b9b9b9;
  width: 100%;
  border-top: 0;
  margin-top: 4px;
}
.fullWidthData .icon-part
.fullWidthData .icon-part img {
  margin-top: 2px;
}
.fullWidthData .label-with-border{
    font-size: 14px;
    font-weight: 600;
    color: #151852;
    border-bottom: 1px solid #b9b9b9;
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 4px;
    margin-top: 6px;
}
.fullWidthData .icon-part .icon-background{
  background: linear-gradient(to bottom, #33CA8F, #0BB8C8);
    border-radius: 50%;
    height: 18px;
    width: 18px;
    min-height: 18px;
    min-width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
}
.fullWidthData .icon-part .icon-background img{
  width: 65%;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
}
.fullWidthData .icon-part.location-part .icon-background img{
  width: 65%;
  margin-top: 0px;
  margin-left: 1px;
}

.fullWidthData p,
.fullWidthData a {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 16px !important;
  text-align: left !important;
  color: #151852;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5vw;
}
.fullWidthData a{
  word-break: break-word;
}

.fullWidthData .content-part {
  text-align: left;
}

.fullWidthData a {
  line-height: 18px !important;
}

.fullWidthData p:last-child {
  margin-bottom: 0;
}

.fullWidthData .costPart {
  margin-bottom: 10px;
}

.fullWidthData .costPart label {
  margin: 2px 0 8px;
  font-size: 14px;
  font-weight: 500;
}

.uniqueFieldData .card-body {
  padding: 0 1rem;
}

.uniqueFieldData .icon-section {
  margin: 0;
}

.uniqueFieldData .fullWidthData li:first-child {
  margin-top: 0.8rem;
}

.uniqueFieldData .fullWidthData li:last-child {
  margin-bottom: 0.8rem;
}