* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    padding-top: 0;
}

.toltip-block {
    margin-bottom: 10px;
    text-align: left;

    &:last-child {
        margin-bottom: 0;
    }

    p {
        margin-bottom: 8px !important;
    }
}

.tooltip-part {
    position: relative;
    cursor: pointer;
}

.custom-tooltip {
    position: absolute;
    font-weight: bold;
    font-size: 24px;
    padding: 6px 10px 8px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #fff;
    z-index: 2;
    min-width: 190px;
    left: -4px;
    bottom: -210px;
    border-radius: 4px;
    filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

    &.tooltip-text {
        .tooltip-text-habit {
            text-align: center;
            margin-top: 14px;
        }
    }

    &.tooltip-top {
        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 0px;
            left: 20%;
            top: 5px;
            border: 12px solid transparent;
            border-top: 0;
            border-bottom: 11px solid #fff;
            transform: translate(-50%, calc(-100% - 5px));
        }
    }
}

.non-schedule-part {
    position: relative;

    .schedule-part-tooltip {
        font-size: 14px;
        padding: 6px 16px 8px;
        text-align: center;
        background-color: #fff;
        border: 1px solid #fff;
        width: 160px;
        position: absolute;
        top: 37px;
        left: -67px;
        z-index: 2;
        filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        display: none;

        p {
            margin-bottom: 0;
            font-size: 14px !important;
            text-transform: initial !important;
            text-align: center !important;
            color: #212529 !important;
            width: 100% !important;

        }

        &.tooltip-top {
            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 0px;
                left: 50%;
                top: 5px;
                border: 8px solid transparent;
                border-top: 0;
                border-bottom: 8px solid #fff;
                transform: translate(-50%, calc(-100% - 5px));
            }
        }
    }

    &.tooltip-click-show {
        &:hover {
            .schedule-part-tooltip {
                display: block;
            }
        }
    }
}

//   inc-dec-part

.inc-dec-part {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;

    .btn {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        margin: 0;
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #5F6477;
        background-color: transparent;

        img {
            width: 60%;
            margin-top: 0;
        }
    }

    .input-no {
        width: 50px;
        padding: 0;
        margin: 0;
        text-align: center;
        border: 0;
        background: transparent;
        font-weight: 500;
        font-size: 16px;

        &::placeholder {
            color: #0d1151;
        }
    }
}

.more-dropdown-part {
    .dropdown {
        background: transparent;
        padding: 0;

        a {
            padding: 0;
        }
    }

    .dropdown-input {
        border: 1px solid #33ca8f;
        color: #33ca8f;
        height: 32px;
        padding: 8px;
        border-radius: 2px;
        width: 193px;
    }

    .dropdown-menu {
        transform: translate3d(-165px, 32px, 0px) !important;
        width: 216px;
        padding-bottom: 0;
    }

    .inner-lock {
        padding: 0px 10px 10px;
    }

    .day-date-part {
        .day-part {
            width: 194px !important;
            margin-right: 0;
            // border: 1px solid #33ca8f;
            color: #33ca8f;
            height: 32px;
            // padding: 5px 6px;
            border-radius: 2px;
            align-items: center;
            font-size: 14px !important;
        }
    }

    .link-part {
        margin: 0;
        list-style: none;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: #151852;
        font-weight: 400;
        justify-content: space-between;
        cursor: pointer;
        text-transform: capitalize;
        padding-right: 0.5rem;
        position: relative;

        a {
            width: 100%;
            padding: 6px 0;
            height: 34px;
            display: flex;
            justify-content: space-between;
        }

        .schedule-link {
            padding-left: 1.8rem;
        }

        .delete-btn {
            color: #e70000 !important;

            &:hover,
            &:focus {
                color: #ff0404 !important;
                font-weight: 500 !important;
            }
        }

        .sync-btn {
            display: flex;
            width: 48%;
            color: white;
            background-color: #33ca8f;
            border-radius: 4px;
            border: 0px;
            text-align: center;
            justify-content: center;
            padding: 0.5rem;
            margin: 0.6rem 0 0;
            font-size: 12px;

            .sync-part-tooltip {
                font-size: 14px;
                padding: 6px 16px 8px;
                text-align: center;
                background-color: #fff;
                border: 1px solid #fff;
                width: 160px;
                min-width: 160px;
                position: absolute;
                top: 155px;
                left: -20px;
                z-index: 2;
                filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
                box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                display: none;

                &.recurring-sync-btn {
                    top: 195px;
                    left: -25px;
                }

                &.tooltip-top {
                    &::before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 0px;
                        left: 50%;
                        top: 5px;
                        border: 8px solid transparent;
                        border-top: 0;
                        border-bottom: 8px solid #fff;
                        transform: translate(-50%, calc(-100% - 5px));
                    }
                }

                p {
                    font-size: 14px !important;
                    text-transform: initial !important;
                    text-align: center !important;
                    color: #212529 !important;
                    width: 100% !important;
                }
            }

            &:hover {
                background-color: #33d797ed;

                .sync-part-tooltip {
                    &.tooltip-click-show {
                        display: block;
                    }
                }
            }

            &.disable {
                background-color: #bdbdbd;
                cursor: not-allowed;

                &:focus {
                    outline: none;
                }
            }
        }

        .time-part {
            justify-content: space-between;
            display: flex;
            width: 194px;

            input {
                width: 31%;
                border: 1px solid #33ca8f;
                color: #33ca8f;
                height: 32px;
                padding: 8px;
                border-radius: 2px;
                margin-top: 8px;
            }
        }
    }
}

.tooltip-text-habit {
    font-size: 11px;
    letter-spacing: 0.3px;
    font-weight: 400;
    margin-top: -8px;
    display: block;
    color: #646464;
    text-align: left;
    font-family: "Poppins", sans-serif !important;
}

.goal-checkbox-part {
    position: absolute;
    left: 7px;
    top: 2px;

    .custom-control {
        z-index: auto !important;
    }

    .custom-control-label {
        z-index: 0 !important;
    }

    .custom-control-input:focus,
    .custom-control-input:focus~.custom-control-label::before {
        box-shadow: none;
    }

    .custom-checkbox .custom-control-label::before {
        border-radius: 3px;
        top: 3PX;
    }

    .custom-checkbox .custom-control-label::after {
        top: 3px;
    }

    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: #33CA8F;
        background-color: #33CA8F;
    }
}

.task-emark-part {
    position: relative;

    .emark-part {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 39px;
        top: 8px;
        cursor: pointer;
        opacity: 0;

        img {
            display: flex;
            justify-content: flex-end;
            z-index: 1;
            /* opacity: .8; */
            /* width: 3%; */
            width: 18px;

            &:hover {
                .emark-tooltip {
                    display: block;
                }
            }
        }

        .emark-tooltip {
            position: absolute;
            font-size: 14px;
            padding: 6px 8px;
            background-color: #fff;
            text-align: center;
            width: 160PX;
            top: 30px;
            left: -71px;
            z-index: 2;
            filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
            display: none;

            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 0px;
                left: 50%;
                top: 5px;
                border: 8px solid transparent;
                border-top: 0;
                border-bottom: 8px solid #ffffff;
                transform: translate(-50%, calc(-100% - 5px));
            }
        }

        &.tooltip-click-show {
            &:hover {
                .emark-tooltip {
                    display: block;
                }
            }
        }
    }

    .goal-main-content {
        margin-bottom: 14px !important;

        &.error-content-part {
            .emark-part {
                opacity: 1;
            }

            .non-schedule-button {
                margin-right: 20px !important;
            }
        }
    }

}

.custom-select-dropdown {
    font-family: "Poppins", sans-serif !important;

    span {
        display: none;
    }

    input {
        &::placeholder {
            font-family: "Poppins", sans-serif;
            padding: 0;
            text-align: center;
            font-size: 14px !important;
            line-height: 14px !important;
            color: #979797;
            font-weight: 400 !important;
        }

        height: 35px;
        background: #ffffff;
        border: 1px solid #33ca8f;
        box-sizing: border-box;
        border-radius: 6px;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 14px;
        transition: 0.3s;
        font-family: "Poppins",
        sans-serif;
        padding: 0;
        text-align: center;
        color: #979797 !important;
    }

    &:focus {
        border: 1px solid #33ca8f;
    }
}

.calendar-part {
    .link-part:disabled {
        a {
            &:disabled {
                color: lightgray !important;
            }
        }
    }

    .non-active {
        color: lightgray;
        cursor: not-allowed;
        pointer-events: none;

        &:hover {
            color: lightgray !important;
        }
    }

    .custom-control-input {
        .custom-control-label {
            &::before {
                background-color: #33CA8F !important;
                opacity: 0.3 !important;
            }
        }
    }

    .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
        background-color: #33CA8F !important;
        border-color: l#33CA8F !important;
        opacity: 0.3 !important;
    }
}

.outline-btn-header {
    border: 1px solid #33CA8F;
    color: #33CA8F;
    padding: 10px 15px;
}

.login-coin-btn-part {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .outline-btn-header {
        border: 1px solid #33CA8F;
        color: #33CA8F;
        padding: 10px 15px;
    }
}

.alert-danger {
    // width: 80%;
    font-family: "Poppins", sans-serif;
    background-color: #f8d7dae3;
    border: 1px solid #f8d7dae3;

    button {
        float: right;
        font-size: 14px;
        padding: 5px 14px;
        position: absolute;
        right: 8px;
        bottom: 7px;
    }

    p {
        margin-bottom: 0 !important;
    }
}

.alert-success {
    width: 80%;
    font-family: "Poppins", sans-serif;

    p {
        margin-bottom: 0 !important;
    }
}

.icon-explainantion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    // font-family: "Poppins", sans-serif;

    ul {
        margin: 0 8px 0 0;
        padding: 0;
        display: flex;
        align-items: center;
        grid-gap: 24px;
        list-style-type: none;

        li {

            &.complete {
                background-image: url("../assets/images/complete_icon.svg");
            }

            &.pending {
                background-image: url("../assets/images/pending_icon.svg");
            }

            &.in-progress {
                background-image: url("../assets/images/in_progress_icon.svg");
            }

            padding-left: 28px;
            border: 0;
            background-repeat: no-repeat;
            background-position: 0 50%;
            background-size: 20px 20px;
            font-size: 16px;
            line-height: 24px;
            color: #505050;
            font-weight: 400;
            text-transform: capitalize;
        }
    }

    ul {
        &.calendar-icons {
            li {
                padding-left: 0px;
                display: flex;
                align-items: center;

                .calendar-status {
                    width: 24px;
                    height: 24px;
                    border-radius: 100px;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-right: 8px;
                    background-size: 60%;

                    &.not-login {
                        background-color: #FFFFFF;
                        border: 1px solid #0d1151;
                        background-color: #0d1151;
                        // background-image: url("../assets/images/schedule.png");
                        background-image: url("../assets/images/s-cloud-white.png");
                    }

                    &.sync {
                        background-color: #0BB8C8;
                        border-color: 1px solid #0BB8C8;
                        // background-image: url("../assets/images/schedule-white.png");
                        background-image: url("../assets/images/s-cloud-white.png");
                    }

                    &.not-sync {
                        background-color: #ef6751;
                        border-color: 1px solid #ef6751;
                        // background-image: url("../assets/images/schedule-white.png");
                        background-image: url("../assets/images/s-cloud-white.png");
                    }
                }
            }
        }
    }
}

.control-dropdown-btn {
    position: absolute;
    top: -6px;
    right: 72px;

    img {
        margin-right: 4px;
    }

    &:focus {
        outline: none;
    }
}

.inc-dec-dropdown {
    .custom-tooltip {
        position: initial;
        opacity: 1;
        background-color: transparent;
        filter: none;
        box-shadow: none;
        padding: 0;

        .tooltip-text {
            .tooltip-text-habit {
                text-align: center;
            }
        }

        .toltip-block {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            p {
                margin-bottom: 0 !important;
                font-size: 13px !important;
                min-width: 70px;
                text-transform: capitalize;
            }
        }
    }
}

.coins-tooltip-part {
    position: relative;
    background-color: #fff;
    padding: 6px 12px;
    border-radius: 25px;

    &.addBoxshdow {
        box-shadow: rgba(17, 12, 46, 0.45) 0px 48px 100px 0px;
    }

    cursor: pointer;

    .custom-tooltip {
        bottom: -83px;
        min-width: 200px;
        left: -35px;

        &.tooltip-top {
            &::before {
                left: 48%;
            }
        }
    }

    .inc-dec-part {
        margin-top: 8px;

        .btn {
            width: 22px !important;
            height: 22px !important;
            border-radius: 50% !important;
            border: 1px solid #5F6477 !important;
            background-color: transparent !important;
        }
    }
}


.loader-position {
    left: -20px;
    top: 1px;
}

.schedule-hover-part {
    position: relative;

    .schedule-dropdown-tooltip {
        position: absolute;
        font-size: 14px;
        padding: 6px 8px;
        background-color: #fff;
        text-align: center;
        width: 160PX;
        top: 30px;
        left: -16px;
        z-index: 2;
        filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
        line-height: 20px;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        display: none;
        text-transform: none;

        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 0px;
            left: 15%;
            top: 5px;
            border: 8px solid transparent;
            border-top: 0;
            border-bottom: 8px solid #ffffff;
            transform: translate(-50%, calc(-100% - 5px));
        }
    }

    &.tooltip-click-show {
        .goal-checkbox-part {
            &:hover {
                .schedule-dropdown-tooltip {
                    display: block;
                    z-index: 2;
                }
            }
        }

    }
}

.day-part-tooltip {
    position: relative;

    .day-tooltip {
        position: absolute;
        font-size: 14px;
        padding: 6px 8px;
        background-color: #fff;
        text-align: center;
        width: 160PX;
        top: 36px;
        left: -16px;
        z-index: 2;
        filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
        line-height: 20px;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        display: none;

        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 0px;
            left: 32%;
            top: 5px;
            border: 8px solid transparent;
            border-top: 0;
            border-bottom: 8px solid #ffffff;
            transform: translate(-50%, calc(-100% - 5px));
        }
    }

    &.tooltip-click-show {
        .day-part {
            color: #000;

            &:hover {
                .day-tooltip {
                    color: #000;
                    display: block;
                }
            }
        }
    }
}

.delete-btn-tooltip {
    position: relative;

    .del-tooltip {
        position: absolute;
        font-size: 14px;
        padding: 6px 8px;
        background-color: #fff;
        text-align: center;
        width: 160PX;
        top: -40px;
        left: -32px;
        z-index: 2;
        filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
        line-height: 20px;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        display: none;
        font-weight: 400;
        z-index: 2;

        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 0px;
            left: 45%;
            top: 32px;
            border: 8px solid transparent;
            border-top: 0;
            border-bottom: 8px solid #ffffff;
            transform: translate(-50%, calc(-100% - 5px));
            transform: rotate(180deg);
        }

        &.coins-tooltip {
            position: absolute;
            font-size: 14px;
            padding: 6px 8px;
            background-color: #fff;
            text-align: center;
            width: 230px;
            top: 45px;
            left: -16px;
            z-index: 2;
            filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
            line-height: 20px;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
            display: none;

            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 0px;
                left: 32%;
                top: 5px;
                border: 8px solid transparent;
                border-top: 0;
                border-bottom: 8px solid #ffffff;
                transform: translate(-50%, calc(-100% - 5px));
            }
        }
    }

    &.tooltip-click-show {
        &:hover {
            .del-tooltip {
                display: block;
            }
        }
    }

    &.tooltip-click-show {
        &.addBoxshdow {
            &:hover {
                .del-tooltip {
                    &.coins-tooltip {
                        display: none !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .task-emark-part {
        .emark-part {
            bottom: 8px;
            top: auto;
        }
    }
}

@media screen and (max-width: 991px) {
    .goal-main-content {
        flex-wrap: wrap;

        .switch-goal-part {
            width: 100% !important;
        }

        .calendar-part {
            width: 100% !important;
        }
    }

    .task-emark-part {
        .emark-part {
            bottom: auto;
            top: 20px;
        }
    }

    .calendar-part {
        font-family: "Poppins", sans-serif;

        .navbar-nav {
            background-color: transparent;
            box-shadow: transparent;
            box-shadow: none !important;
            padding: 0;
            margin: 0;
            width: auto;

            &::before {
                display: none;
            }
        }

        .nav-link {
            color: #151852 !important;
            cursor: pointer;
            color: #151852;
            font-weight: 400 !important;
            font-family: "Poppins", sans-serif;
            background-color: transparent;

            &.active {
                background-color: transparent;
            }
        }

        .dropdown-input {
            font-family: "Poppins", sans-serif;
        }

        .input-part {
            input {
                font-family: "Poppins", sans-serif;
            }
        }

        .sync-btn {
            font-family: "Poppins", sans-serif;
        }
    }
}

@media screen and (max-width:820px) {
    .icon-explainantion ul li {
        font-size: 13px;
        padding-left: 22px;
        background-size: 16px 16px;
    }

    .icon-explainantion ul li .calendar-status {
        width: 20px !important;
        height: 20px !important;
    }

    .alert-danger,
    .alert-success {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .task-emark-part .emark-part {
        bottom: auto;
        top: 21px;
    }

    .icon-explainantion {
        ul {
            grid-gap: 14px;

            li {
                font-size: 12px;
                padding-left: 24px;
            }
        }
    }

    .alert-danger,
    .alert-success {
        width: 70%;
    }
}

@media screen and (max-width: 767px) {
    .goal-main-content {
        .switch-goal-part {
            width: 100% !important;

            h6 {
                max-width: 70% !important;
            }
        }

        .calendar-part {
            width: 100% !important;
        }
    }

    .header-content .right-content {
        justify-content: flex-end !important;
    }

    .task-emark-part .emark-part {
        bottom: 12px;
        top: auto;
    }

    .icon-explainantion {

        .left,
        .right {
            width: 100%;
        }

        .left {
            margin-bottom: 10px;
        }

        ul {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            li {
                font-size: 11px;
            }
        }
    }

    .login-coin-btn-part {
        flex-wrap: wrap;

        .alert-danger,
        .alert-success {
            width: 100%;
        }

        .outline-btn-header {
            width: 100%;
            margin-bottom: 1rem;
        }

        .allocation-button {
            width: 100%;
        }
    }

}

// .goal-page-container{
//     // width: 88% !important;
//     padding-bottom: 1rem;
//     ul{
//         margin-bottom: 0;
//     }
// }
@media screen and (max-width:1440px) {
    .goal-page-container {
        width: 90% !important;
    }
}

@media screen and (max-width:1440px) {
    .goal-page-container {
        width: 90% !important;
    }
}

@media screen and (max-width:1366px) {
    .goal-page-container {
        width: 95% !important;
    }
}

@media screen and (max-width:1280px) {
    .goal-page-container {
        width: 90% !important;
    }
}

@media screen and (max-width:1024px) {
    .goal-page-container {
        width: 95% !important;
    }

    .homeNav li {
        font-size: 12px;
    }

    .homeNav li .nav-link {
        font-size: 12px;
    }
}

// @media screen and (max-width:1280px) {
//     .goal-page-container{
//         width: 95% !important;
//     }
// }

// .goal-page-footer{
//     width: 90%;
//     max-width: 1440px;
// }
// @media screen and (max-width:1280px) {
//     .goal-page-footer{
//         width: 95%;
//     }
// }
// @media screen and (max-width:1200px) {
//     .goal-page-footer{
//         width: 95% !important;
//     }
// }


.goal-page-footer {
    width: 88%;
    max-width: 1440px;
}

@media screen and (max-width:1366px) {
    .goal-page-footer {
        width: 95%;
    }
}

@media screen and (max-width:1280px) {
    .goal-page-footer {
        width: 92%;
    }
}

@media screen and (max-width:1200px) {
    .goal-page-footer {
        width: 95% !important;
    }
}

@media screen and (max-width:991px) {
    .goal-page-footer {
        width: 98% !important;
    }
}

.incremental-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.coins-overlay {
    position: fixed;
    top: 0;
    background-color: transparent;
    height: 100vh;
    width: 100vw;
    left: 0;
    z-index: 1;
}

.chips-btn-overlay {
    position: fixed;
    background-color: transparent;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1;
}

.dropdown.symptom.show i.fa-chevron-down {
    transform: rotate(180deg);
    margin-top: -2px;
}


.more-btn-hover-effect {
    position: relative;

    .more-btn-hover {
        font-size: 14px;
        padding: 6px 16px 8px;
        text-align: center;
        background-color: #fff;
        border: 1px solid #fff;
        width: 250px;
        min-width: 250px;
        position: absolute;
        top: 45px;
        left: -100px;
        z-index: 2;
        filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        display: none;

        &.tooltip-top {
            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 0px;
                left: 50%;
                top: 5px;
                border: 8px solid transparent;
                border-top: 0;
                border-bottom: 8px solid #fff;
                transform: translate(-50%, calc(-100% - 5px));
            }
        }

        p {
            font-size: 14px !important;
            text-transform: initial !important;
            text-align: center !important;
            color: #212529 !important;
            width: 100% !important;
        }
    }

    &:hover {
        .more-btn-hover {
            &.tooltip-click-show {
                display: block;
            }
        }
    }
}