.privacy-policy-text {
  /* border: 2px solid grey; */
  width: 80%;
  padding: 50px;
  align-items: center;
  border-radius: 10px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  background-color: #fff !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.title {
  color: black;
}


@media screen and (max-width:767px) {
  .privacy-policy-text{
    width: 90%;
    margin: 100px 20px 50px 20px;
    padding: 20px 10px;
  }
  .privacy-policy-text ol,  .privacy-policy-text ul{
    padding-left: 1.2rem;
  }
  .privacy-policy-text ol ol{
    padding-left: 0;
  }
}


.privacy-policy-background{
  position: absolute;
  background-image: url("../assets/images/top-bg-bottom-gradient.png");
  width: 100%;
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
  background-color: rgb(236, 248, 248);
}