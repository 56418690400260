.CarouselWrapper {
  
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    min-height: 1px;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
}

.slide-wrapper {
  border: 1px solid red;
}

.left-rounded-arrow {
  width: 36px;
  height: 36px;
  background-size: 100% 100%;
  display: block;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  background-color: transparent;
  background-repeat: none;
  background-image: url("../assets/images/Left.png");
  // background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.79 19.25'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23000;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3E22%3C/title%3E%3Cpath class='cls-1' d='M13.89,14.52a.6.6,0,0,0,.53-.37.59.59,0,0,0-.11-.63L6,5.22a.5.5,0,0,1-.12-.29A.4.4,0,0,1,6,4.65l8.3-8.35a.59.59,0,0,0,0-.83l0,0h0a.59.59,0,0,0-.85,0h0l-8.3,8.3A1.62,1.62,0,0,0,5.16,6l8.3,8.3a.69.69,0,0,0,.42.18Z' transform='translate(-4.7 4.73)'/%3E%3C/svg%3E");

  &:focus {
    outline: none;
  }

  &_left {
    left: -60px;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    z-index: 3;
  }

  &_right {
    transform: rotate(180deg);
    right: -60px;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    z-index: 3;
  }
}


@media (max-width: 1250px) {
  .left-rounded-arrow {
    &_left {
      left: -15px;
    }
    &_right {
      right: -15px;
    }
  }
}