* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding-top: 0;
}

p {
  margin-bottom: 0.5vw;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header--alt {
  background: rgb(223, 255, 243);
}

.header--alt .navbar-container,
.custom-w {
  max-width: 1440px;
  width: 95% !important;
  flex-basis: 95% !important;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.navbar-container .navbar {
  padding: 0;
}

.resources-card{
  position: relative;
  overflow: hidden;
}
.resources-card .botton-right-image{
  position: absolute;
    bottom: 1px;
    right: -40px;
    z-index: 0;
    /* width: 65%; */
    width: 26%;
    transform: rotate(-13deg);
}
.resources-card .botton-right-image .green-leaf-image{
    position: absolute;
    width: 54%;
    bottom: -5px;
}
.resources-card .botton-right-image .gray-leaf-image{
    position: absolute;
    width: 41%;
    bottom: 0px;
    transform: rotate(10deg);
    right: 46px;
}
.filters .small-dropdown-btn{
    border: 1px solid #34ca8f;
    background-color: #34ca8f;
    background: #34ca8f;
}
.filters .small-dropdown-btn .dropdown-toggle, .filters .custom-dropdown button{
  padding: 10px 20px !important ;
}

@media (max-width: 1200px) {

  .header--alt .navbar-container,
  footer .container,
  .custom-w {
    width: 100%;
    flex-basis: 100%;
  }
}

footer {
  padding: 30px 20px 20px;
}

// .footer-block {
//   display: flex;
//   justify-content: space-between;
//   .block {
//     min-width: 18%;
//   }
// }
.header--alt .btn.signinBtn,
.gradient-bg {
  background: linear-gradient(to bottom, #33CA8F, #0BB8C8);
  color: #fff !important;
}

.cards-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 18px;
  padding-top: 24px;
  padding-bottom: 90px;
}


.masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

main {
  font-family: "Poppins";
}

.masonry-grid_column {
  padding-left: 15px;
  background-clip: padding-box;
}

.masonry-grid_column>div {
  margin: 15px auto;
}

.rightside {
  position: absolute;
  top: 0px;
  left: 600px;
}

.leftside {}


.resource-cards {
  width: 1300px;
  position: relative;
}

.btn {
  box-shadow: none;
  z-index: 9;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 1024px) {
  .cards-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px;
    padding-top: 24px;
    padding-bottom: 40px;

  }

}

@media (max-width: 767px) {
  .cards-row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 18px;
    padding-top: 24px;
    padding-bottom: 40px;
  }

  .icon-section {
    padding: 0;
  }

  .navbar-container {
    padding: 20px 0;
  }

}

.custom-tab-link {
  padding: 5px 10px 0px;
}

.custom-tab-link.active {
  color: #33ca8f !important;
}


@media (max-width:991px) {
  .nav-link.custom-tab-link {
    color: #151852 !important;
  }

  .nav-link.custom-tab-link.active {
    color: #33ca8f !important;
  }
}
.education-tab-part{
  margin-top: 1rem;
  margin-left: 1.5rem;
}

.education-tab-part .nav-item{
  font-size: 14px;
  vertical-align: bottom;
  border: 2px solid #ddd;
  border-left: 0;
  padding: 0px 18px 8px;
  margin-right: -4px;
  display: inline-block;
  position: relative;
  z-index: 0;
  color: #33CA8F;
  background: white;
  z-index: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: all .2s;
}

.education-tab-part .nav-item::before{
  display: block;
    content: " ";
    position: absolute;
    top: 0px;
    height: 105%;
    width: 33px;
    background-color: #ffffff;
    left: -10px;
    transform: skew(-25deg, 0deg);
    border-left: 2px solid #ddd;
    border-radius: 5px 0 0 0;
    border-bottom: 2px solid #ddd;
    transition: all .1s;
}
.education-tab-part .nav-item::after{
  display: block;
    content: " ";
    position: absolute;
    top: 0px;
    height: 105%;
    width: 33px;
    background-color: #ffffff;
    right: -11px;
    transform: skew(25deg, 0deg);
    border-right: 2px solid #ddd;
    border-radius: 0px 5px 0 0;
    border-bottom: 2px solid #ddd;
    transition: all .1s;
}

.education-tab-part .nav-item.active{
  z-index: 1;
    background-color: #33CA8F;
    // transform: scale(1.02);
    /* margin-bottom: 0px; */
    margin-top: -4px;
    margin-bottom: 0px;
    border-bottom: 2px solid #33CA8F;
    border-color: #33CA8F;
}
.education-tab-part .nav-item.active::before{
  border-left: 2px solid #33CA8F;
  border-bottom: 2px solid #33CA8F;
  background-color: #33CA8F;
}
.education-tab-part .nav-item.active::after{
  border-right: 2px solid #33CA8F;
  border-bottom: 2px solid #33CA8F;
  background-color: #33CA8F;
}

.education-tab-part .nav-item .nav-link{
  border: 0;
  transition: background-color .2s;
  padding-top: 8px;
}
.education-tab-part .nav-item .nav-link:hover{
  border: 0;
  border-color: transparent !important;
}
.education-tab-part .nav-item .nav-link.active{
  border: 0;
  border-color: transparent !important;
  color: white !important;
  background-color: #33CA8F;
  padding-top: 10px;
}

.customSlider-tab-part.inner-heading-tab .nav-item.active{
  margin-top: 0px;
}
.customSlider-tab-part.inner-heading-tab .nav-item::after{
  right: -12px;
}
.customSlider-tab-part.inner-heading-tab .nav-item.active::before {
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  background-color: #fff;
}
.customSlider-tab-part.inner-heading-tab .nav-item.active::after {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  background-color: #fff;
}
.customSlider-tab-part.inner-heading-tab .nav-item.active{
  background-color: #fff;
  border-color: #fff;
}
@media screen and (max-width:768px) {
  .education-tab-part{
    margin: 1rem;
  }
}
@media screen and (max-width:767px) {
  .education-tab-part{
    overflow: auto;
    flex-wrap: nowrap;
    width: 100%;
    overflow-y: hidden;
    margin: 0;
  }
  .education-tab-part .nav-item{
    margin-left: 18px;
  }
  .education-tab-part .nav-item .nav-link{
    white-space: nowrap;
  }
}