* {
    outline: 0;
}

.therapyContent {
    border-radius: 10px;
    border-color: white;
    width: 80%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    position: absolute;
    width: 720px;
    height: 570px;
    top: 100px;
    left: 45px;
}

.fbn-logo {
    width: 270;
    height: 56;
    margin-top: 12;
    margin-left: 26;
}

.therapyTitle {
    /* height: 42px; */
    /* margin-top:15px; */
    /* margin-left:12px; */
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.15;
    color: #151852;
}

.therapyBody {
    /* overflow-y: hidden !important; */
    /* font-family: "CircularStdBook"; */
    /* margin-left:30px; */
    /* margin-top:10px; */
    color: #50597C;
    z-index: 1;
    font-weight: 400;
    font-size: 21px;
    line-height: 1.5;
}


.therapyBody2 {
    overflow-y: auto;
    /* font-family: "CircularStdBook"; */
    /* margin-left:30px; */
    /* margin-top:10px; */
    color: #50597C;
    z-index: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
}



.therapyDialog {
    font-family: "CircularStdBook";
}

.therapyHeader {
    border-bottom: 0 none;
}

.therapyFooter {
    position: absolute;
    top: 379px;
    left: 0;
    width: 732px;
}

.therapyclosebtn {
    width: 154px;
    height: 48px;
    z-index: 3;
    color: #151852;

    background: #FFFFFF;
    border: 1px solid #DDDFE3;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: auto;
}

.therapystartbtn {
    background: linear-gradient(180deg, #33CA8F 0%, #0BB8C8 100%);
    box-shadow: 0px 8px 18px rgba(51, 202, 143, 0.18);
    border-radius: 4px;
    width: 154px;
    height: 48px;
    left: 45px;
    z-index: 2;

    border: transparent;
    color: white;
}

.navigate-circle {
    height: 45px;
    width: 45px;
    border-radius: 23px;
    border: 2px solid #abe6ff;
}

.therapyclosebtn:hover {
    color: #616161;
}

.therapystartbtn:hover {
    /* color:#258f66; */
}

.navigateButtonLeft {
    border-color: transparent;
    background-color: transparent;
    position: absolute;
    left: 510px;
    top: -175px;
    outline: 0 !important;
}

.navigateButtonLeftDisabled {
    border-color: transparent;
    background-color: transparent;
    position: absolute;
    left: 510px;
    top: -175px;
    outline: 0 !important;
    filter: grayscale(100%) contrast(75%);
}

.navigateButtonRight {
    border-color: transparent;
    background-color: transparent;
    position: absolute;
    left: 570px;
    top: -175px;
    outline: 0 !important;
}

.navigateButtonRightDisabled {
    border-color: transparent;
    background-color: transparent;
    position: absolute;
    left: 570px;
    top: -175px;
    outline: 0 !important;
    filter: grayscale(100%);
}


.navigateArrow {
    position: relative;
    top: 31px;
}

.arrow {
    margin-left: 10px;
    margin-bottom: 2px;
}

.otherNumbers {
    color: #151852;
    border-color: transparent;
    height: 30px;
    width: 30px;
    border-radius: 15;
    background-color: transparent;
    bottom: 2px;
    right: 1px;
}

.otherNumbers:hover {
    color: #5993ff;
    border-color: transparent;
    background-color: #f7f7f7;
    height: 30;
    width: 30;
    border-radius: 15;
    background-color: transparent;
}

.number-circle {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    border: 1px solid #B5C5E7;
    z-index: 10;
    margin-left: 8px;
}


.currentNumber {
    color: white;
    border-color: transparent;
    background-color: #33CA8F;
    height: 30;
    width: 30;
    border-radius: 15px;
    bottom: 2px;
    right: 1px;
}

.currentNumber:hover {
    border-color: transparent;
    height: 30;
    width: 30;
    border-radius: 15;
    background-color: #33CA8F;
}

.numberButtons {
    border-radius: 20px;
    border: 1px solid black;
}

.activeButton:hover {
    height: 27px;
    width: 27px;
    border-radius: 15;
    background-color: #33CA8F;

}


.activeButton {
    height: 27px;
    width: 27px;
    border-radius: 15;
    background-color: #33CA8F;

}


.centerplease {
    font-family: "CircularStdBook";
    position: absolute;
    left: 520px;
    top: 108px;

}

.left-ellipse-therapy {
    position: absolute;
    top: 520px !important;
    left: 50px !important;
    ;
}

.right-ellipse-therapy {
    position: absolute;
    top: 448px !important;
    left: 90px !important;
    opacity: 0.3;


}

.learnMoreHeader {
    border-bottom: 0 none;
}

.learnMoreFooter {
    position: absolute;
    top: 379px;
    left: 0;
    width: 732px;
    border-color: transparent;
}

.learnMoreCloseBtn {
    position: absolute;
    height: 50px;
    width: 100px;
    right: 55px;
    bottom: -70px;
    background-color: #33CA8F;
    border-color: transparent;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 100;
    font-family: "CircularStdBook";
    color: white;
    position: relative;
    outline: 0 !important;
}

.learnMoreCloseBtn:hover {
    background-color: #258f66;
}


pre {
    font-family: "CircularStdBook";
}

pre,
.holder-description {
    text-align: justify !important;
    border: none;
    word-break: normal;
    word-wrap: normal;
    white-space: pre-line;
}




.holder-description {
    /* width:700px; */
    /* height:700px; */
    display: block;
    /* margin:10px; */
}

.number-circle {
    position: relative;
}

.number-circle a {
    /* padding: 5px 10px; */
    position: absolute;
    width: 100%;
    text-align: center;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

a.activeButton {
    background-color: #33CA8F;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.text-page {
    margin: 0px;
}

.currentNumber:hover {
    color: #000;
}


.modal-footer {
    padding: 0;
}

.modal-backdrop.show+iframe {
    pointer-events: none;
    display: none;
}

.footer-content {
    padding: 0 40px;
    display: flex;
    align-items: center;
}

.footer-content p {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-transform: capitalize;
    color: #151852;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
}

.right-up-arrow {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.right-up-arrow .right-arrow {
    border-radius: 50%;
}

.right-up-arrow .right-arrow i {
    transform: rotate(180deg);
}

.paginate {
    display: block;
    height: 290px;
    position: relative;
    overflow: hidden;
}

.content p {
    margin: -1px;
}

.content ul {
    margin: 0;
}